/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, Provider, inject } from '@angular/core';
import { HttpRequest, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthJwtInterceptor } from './interceptors/auth-jwt.interceptor';
import { AUTH_JWT_OPTIONS } from './models/auth-jwt-options.token';
import { AuthJwtHelperService } from './services/auth-jwt-helper.service';
import { AuthTokenProviderService } from './services/auth-token-provider.service';
import { AccessTokenGuard } from './guards/access-token.guard';

export interface AuthJwtConfig {
  tokenGetter?: (
    request?: HttpRequest<any>
  ) => string | null | Promise<string | null>;
  headerName?: string;
  authScheme?: string | ((request?: HttpRequest<any>) => string);
  allowedDomains?: Array<string | RegExp>;
  disallowedRoutes?: Array<string | RegExp>;
  throwNoTokenError?: boolean;
  skipWhenExpired?: boolean;
}

export interface AuthJwtModuleOptions {
  authJwtOptionsProvider?: Provider;
  config?: AuthJwtConfig;
}


/**
 * Module for AuthJwtInterceptor
 * Derived from https://github.com/auth0/angular2-jwt
 */

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class AuthJwtModule {
  constructor() {
    const parentModule = inject(AuthJwtModule, { optional: true, skipSelf: true });

    if (parentModule) {
      throw new Error(
        `AuthJwtModule is already loaded. It should only be imported in your application's main module.`
      );
    }
  }
  static forRoot(options: AuthJwtModuleOptions): ModuleWithProviders<AuthJwtModule> {
    return {
      ngModule: AuthJwtModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthJwtInterceptor,
          multi: true,
        },
        options.authJwtOptionsProvider || {
          provide: AUTH_JWT_OPTIONS,
          useValue: options.config,
        },
        AuthJwtHelperService,
        AuthTokenProviderService,
        AccessTokenGuard
      ],
    };
  }
 }


