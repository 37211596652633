import { AppRoutes } from './models/app-route.model';

/**
 * Application routes
 */
export const routes: AppRoutes = [
  {
    path: 'aktuell',
    loadChildren: () => import('./modules/aktuell/aktuell.routes').then(mod => mod.AKTUELL_ROUTES),
  },
  {
    path: 'wetter',
    loadChildren: () => import('./modules/wetter/wetter.routes').then(mod => mod.WETTER_ROUTES),
  },
  {
    path: 'wetter-aushang',
    loadChildren: () => import('./modules/wetter-aushang/wetter-aushang.routes').then(mod => mod.WETTER_AUSHANG_ROUTES),
  },
  // {
  //   path: 'vorhersage',
  //   loadChildren: () => import('./modules/vorhersage/vorhersage.module').then(mod => mod.VorhersageModule),
  // },
  {
    path: 'club',
    loadChildren: () => import('./modules/club/club.routes').then(mod => mod.CLUB_ROUTES)
  },
  {
    path: 'termine',
    loadChildren: () => import('./modules/termine/termine.routes').then(mod => mod.TERMINE_ROUTES),
  },
  {
    path: 'boote',
    loadChildren: () => import('./modules/boote/boote.routes').then(mod => mod.BOOTE_ROUTES),
  },
  {
    path: 'jugend',
    loadChildren: () => import('./modules/jugend/jugend.routes').then(mod => mod.JUGEND_ROUTES),
  },
  {
    path: 'regatta',
    loadChildren: () => import('./modules/regatta/regatta.routes').then(mod => mod.REGATTA_ROUTES),
  },
  {
    path: 'links',
    loadChildren: () => import('./modules/links/links.routes').then(mod => mod.LINKS_ROUTES),
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./modules/kontakt/kontakt.routes').then(mod => mod.KONTAKT_ROUTES),
  },
  {
    path: 'ansprechpartner',
    loadChildren: () => import('./modules/ansprechpartner/ansprechpartner.routes').then(mod => mod.ANSPRECHPARTNER_ROUTES),
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/impressum/impressum.routes').then(mod => mod.IMPRESSUM_ROUTES),
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./modules/datenschutz/datenschutz.routes').then(mod => mod.DATENSCHUTZ_ROUTES),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: 'member',
    loadChildren: () => import('./modules/member/member.routes').then(mod => mod.ROUTES)
  },
  {
    path: 'ref',
    loadChildren: () => import('./modules/ref/ref.routes').then(mod => mod.ROUTES)
  },
  // {
  //   path: 'member-support',
  //   loadChildren: () => import('./modules/member-support/member-support.routes').then(mod => mod.ROUTES)
  // },
  // {
  //   path: 'member-admin',
  //   loadChildren: () => import('./modules/member-admin/member-admin.routes').then(mod => mod.ROUTES)
  // },
  {
    path: '**',
    redirectTo: 'club',
    pathMatch: 'full'
  }
];